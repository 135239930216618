import React, { useState } from "react"
import styled from "styled-components"
//components
import MenuMobile from "../components/menuMobile"
import { IconButton, Button, Link } from "gatsby-theme-material-ui"
import { MdMenu, MdMailOutline, MdClose } from "react-icons/md"
import Collapse from "@material-ui/core/Collapse"
import Logo from "../images/svg/globalhitss.svg"
import pageData from "../data/pageData"

const Header = location => {
  const activeUrl = JSON.stringify(location.location)
  const [state, setState] = useState(false)
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [subMenu, setSubMenu] = useState([])
  const [subPath, setSubPath] = useState("")
  const handleSubMenu = path => {
    if (!openSubMenu) {
      setOpenSubMenu(true)
    } else if (subPath === path) {
      setOpenSubMenu(false)
    }
  }
  const toggleDrawer = state => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    if (
      event &&
      event.type === "click" &&
      event.target.parentNode.parentNode.tagName === "BODY"
    ) {
      setState(false)
    }
    if (!!state) {
      setState(true)
    }
  }
  const navData = pageData.nav
  const nav = () =>
    !!navData.items.length && (
      <nav className="nav">
        {navData.items.map((item, i) =>
          !!item.child.length ? (
            <Link
              key={i}
              className={`navLink ${
                openSubMenu && subPath === item.path && "open"
              } ${activeUrl.startsWith("/soluciones", 1) && "active"}`}
              onClick={() => {
                handleSubMenu(item.path)
                setSubMenu(item.child)
                setSubPath(item.path)
              }}
            >
              {item.name}
            </Link>
          ) : (
            <Link
              key={i}
              className="navLink"
              activeClassName="active"
              to={`/${item.path}`}
            >
              {item.name}
            </Link>
          )
        )}
        <Link className="navLink" activeClassName="active" to="/contacto/">
          <Button variant="outlined" color="primary">
            Contacto
          </Button>
        </Link>
      </nav>
    )
  const SubNav = () =>
    !!subMenu.length && (
      <SubMenu>
        {subMenu.map((item, i) => (
          <Link
            key={i}
            className="navLink"
            activeClassName="active"
            to={`/${item.path}`}
          >
            {item.name}
          </Link>
        ))}
      </SubMenu>
    )

  return (
    <div>
      <Container>
        <StyledHeader>
          <div className="toolbar">
            <div className="menuButton">
              <IconButton
                color="primary"
                aria-label="Abrir menpu"
                onClick={toggleDrawer(true)}
              >
                {!!state ? <MdClose /> : <MdMenu />}
              </IconButton>
            </div>
            <div className="brand">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="navbar">{nav()}</div>
            <div className="contactButton">
              <IconButton
                to="/contacto/"
                color="secondary"
                aria-label="Contacto"
                style={{ color: "#999" }}
              >
                <MdMailOutline />
              </IconButton>
            </div>
          </div>
        </StyledHeader>
        <Collapse
          in={openSubMenu}
          timeout="auto"
          unmountOnExit
          style={{ position: "absolute", width: "100%", zIndex: "-1" }}
        >
          <SubNav />
        </Collapse>
      </Container>
      <MenuMobile toggleDrawer={toggleDrawer} state={state} navData={navData} />
    </div>
  )
}

const SubMenu = styled.div`
  display: none;
  @media screen and (min-width: 960px) {
    background: #fff;
    margin: 0 3rem;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    border-radius: 0 0 8px 8px;
    > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;
      margin: 0.4rem 0;
      @media (min-width: 1200px) {
        flex-basis: 20%;
      }
    }
    a {
      color: ${props => props.theme.colors.text.secondary};
      font-size: 0.875rem;
      &:hover {
        text-decoration: none;
        font-weight: bold;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
`
const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1201;
`
const StyledHeader = styled.header`
  background: ${props => props.theme.colors.headerBackground};
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  padding: 0 0.5rem;
  height: ${props => props.theme.header.height.mobile};
  display: flex;
  align-items: center;
  width: 100%;
  a {
    color: ${props => props.theme.colors.text.primary};
  }
  .toolbar {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    .menuButton {
      justify-self: start;
    }
    .brand {
      justify-self: center;
      display: flex;
      a {
        display: inline-flex;
      }
      svg {
        width: 150px;
        height: 30px;
      }
    }
    .contactButton {
      justify-self: end;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    a {
      white-space: nowrap;
    }
  }
  .navbar {
    display: none;
  }
  @media screen and (min-width: 350px) {
    .brand svg {
      width: 185px;
      height: 37px;
    }
  }
  @media screen and (min-width: 960px) {
    padding: 0 1.5rem;
    height: ${props => props.theme.header.height.desktop};
    .menuButton,
    .contactButton {
      display: none;
    }
    .navbar {
      display: flex;
      justify-content: flex-end;
      .navLink + .navLink {
        margin-left: 3.125rem;
      }
      .navLink {
        position: relative;
        &:hover {
          text-decoration: none;
          cursor: pointer;
          color: ${props => props.theme.colors.nav.active};
        }
        &.active {
          color: ${props => props.theme.colors.nav.active};
          button {
            color: white;
            background-color: #269094;
          }
        }
        &.open {
          &::after {
            content: "";
            width: 100%;
            height: 8px;
            background-color: #007881;
            bottom: -45px;
            left: 0;
            position: absolute;
          }
        }
      }
    }
    .toolbar {
      grid-template-columns: 1fr 1fr;
      .brand {
        justify-self: start;
        svg {
          width: 195px;
          height: 40px;
        }
      }
      .navbar {
        justify-self: end;
      }
    }
  }
`

export default Header
