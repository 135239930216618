import React from "react"
import styled from "styled-components"
import Logo from "../images/svg/globalhitss.svg"
import Iso from "../images/svg/iso.svg"
import { IconButton, Link } from "gatsby-theme-material-ui"
import pageData from "../data/pageData" // icon component
import * as FontIcon from "react-icons/fa"
import LogoDenuncias from "../images/source/boton-denuncias.png"
import LogoGPTW from "../assets/img/GPTW.svg"

const Icon = props => {
  const { iconName } = props
  const icon = React.createElement(FontIcon[iconName])
  return icon
}
const Footer = () => (
  <Container>
    <div className="social">
      <div className="buttons">
        {pageData.footer.network.items.map((item, i) => (
          <Link key={i} href={item.path} target="_blank">
            <IconButton color="inherit" aria-label={`Ir a ${item.title}`}>
              <Icon iconName={item.icon} />
            </IconButton>
          </Link>
        ))}
      </div>
      <div className="great-mobile">
        <LogoGPTW />
      </div>
    </div>

    <div className="denuncia_small">
      <a href="https://denuncias.americamovil.com/">
        <img width={350} src={LogoDenuncias} alt="logo" />
      </a>
    </div>

    <div className="contact">
      <Link className="footerLogo" to="/">
        <Logo />
      </Link>
      <div className="mcontact">
        <Link className="iso" to="/">
          <Iso />
        </Link>
        <div className="contact_info">
          <Link color="inherit" href="tel:+541154368100">
            +54 11 5436 8100
          </Link>
          <p className="rrhh">
            Trabaja con nosotros:{" "}
            <Link href="mailto:seleccion.arg@globalhitss.com">
              seleccion.arg@globalhitss.com
            </Link>
          </p>
          <p className="rrhh2">
            <Link href="mailto:seleccion.arg@globalhitss.com">
              seleccion.arg@globalhitss.com
            </Link>
          </p>
          <div className="info">
            <Link key="privacidad" href="/politicas-de-privacidad">
              Políticas de privacidad
            </Link>
            <Link key="tyc" href="/terminos-y-condiciones">
              Términos y condiciones
            </Link>
            <Link key="calidad" href="/politicas-de-calidad">
              Políticas de calidad
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className="denuncia">
      <a href="https://denuncias.americamovil.com/">
        <img width={350} src={LogoDenuncias} alt="logo" />
      </a>
    </div>

    <div className='great'>
      <LogoGPTW />
    </div>
  </Container>
)

export default Footer

const Container = styled.footer`
  text-align: center;
  background: #f9f9f9;
  @media screen and (min-width: 600px) {
    display: flex;
    background-color: ${props => props.theme.colors.background.footer};
    justify-content: space-between;
    padding: 32px 32px 32px 40px;
    align-items: center;
  }
  .contact {
    background-color: ${props => props.theme.colors.background.footer};
    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      order: 1;
    }
  }
  .mcontact {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 25px 0;
    @media screen and (min-width: 600px) {
      justify-content: flex-start;
      padding: 0;
    }

    .contact_info {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
      }
    }

    > div {
      @media screen and (max-width: 600px) {
        align-items: flex-start;
      }
    }

    @media screen and (max-width: 600px) {
      margin: 0 auto;
    }

    .rrhh {
      margin-bottom: 10px;
      padding-top: 10px;
      @media screen and (max-width: 600px) {
        width: 210px;
        display: none;
      }
    }

    .rrhh2 {
      display: none;
      padding-top: 0px;
      margin-bottom: 0;
      @media screen and (max-width: 600px) {
        display: block;
      }

      a {
        text-decoration: none;
        color: #000000d1;
      }
    }
  }
  .iso {
    margin-right: 15px;
    @media screen and (min-width: 600px) {
      display: none;
    }
    svg {
      width: 32px;
      height: 30px;
    }
  }
  .footerLogo {
    display: none;
    @media screen and (min-width: 600px) {
      display: block;
      margin-bottom: 10px;
    }
    svg {
      width: 165px;
      height: 33px;
    }
  }

  .social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
    padding: 70px 0 0 0;
    @media screen and (max-width: 600px) {
      padding: 0;
    }
    a {
      color: ${props => props.theme.colors.icon.footer};
    }
    @media screen and (min-width: 600px) {
      order: 2;
    }
    .buttons {
      text-align: right;

      @media screen and (max-width: 600px) {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }
    }
  }

  .contact {
    .info {
      a {
        color: black;
        padding-left: 40px;
        font-weight: bold;

        @media screen and (max-width: 600px) {
          padding-left: 0px;
          margin-bottom: 10px;
        }
      }
      a:first-child {
        padding-left: 0px;
      }

      @media screen and (max-width: 600px) {
        display: none;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .denuncia {
    img {
      margin: 0;
    }
    @media screen and (min-width: 600px) {
      order: 3;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
    height: 150px;
  }

  .denuncia_small {
    display: none;
    img {
      margin: 0;
    }
    @media screen and (max-width: 600px) {
      display: block;
    }
  }

  .great {
    order: 4;
    margin-top: -49px;
    margin-left: 24px;
    display: none;
    @media screen and (min-width: 600px) {
      display: block;
    }
  }

  .great-mobile {
    display: block;
    @media screen and (min-width: 600px) {
      display: none;
    }
  }
`
