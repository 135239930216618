export default {
  body: {
    background: "#FFFFFF",
  },
  header: {
    height: {
      mobile: '74px',
      desktop: '110px',
    }
  },
  colors: {
    headerBackground: "#FFFFFF",
    nav: {
      active: "#00ABC2",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
      light: "#ffffff"
    },
    background: {
      primary: "#ECEFF4",
      secondary: "#eCfFF4",
      footer: "#F9F9F9",
    },
    button: {
      background: {
        primary: "#00ABC2",
        secondary: "#eCfFF4",
      },
      text: {
        primary: "#ffffff",
        secondary: "#00ABC2",
      },
    },
    icon: {
      primary: "#00ABC2",
      secondary: "#999999",
      footer: "#007881",
      services: "#EDD904",
    },
    cardBackground: "#E5E9F0",
  },
};