import React, { useState } from 'react'
import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from "gatsby"
import { MdChevronRight, MdExpandMore } from 'react-icons/md'
import Collapse from '@material-ui/core/Collapse'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const MenuMobile = ({ toggleDrawer, state, navData }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [subPath, setSubPath] = useState('')
  const handleOpen = (path) => {
    if (open && subPath !== path) {
      setOpen(true)
    } else {
      setOpen(!open)
    }
  }
  const list = () => {
    const navMenu = () => (
      !!navData.items.length &&
      <nav className='nav'>
        <Link className='navLink' activeClassName='active' to="/">
          <ListItem button>
            <ListItemText primary='Home' />
          </ListItem>
        </Link>
        {navData.items.map((item, index) =>
          !!item.child.length ?
            <div key={index}>
              <ListItem className='navLink' button
                onClick={() => {
                  setSubPath(item.path)
                  handleOpen(item.path)
                }
                }>
                <ListItemText primary={item.name} />
                {open && subPath === item.path ? <MdExpandMore className='icon' /> : <MdChevronRight className='icon' />}
              </ListItem>
              <Collapse in={open && subPath === item.path} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.child.map((subItem, i) =>
                    <Link key={i} className='navLink' activeClassName='active' to={`/${subItem.path}`}>
                      <ListItem button className={classes.nested}>
                        <ListItemText primary={subItem.name} />
                      </ListItem>
                    </Link>
                  )}
                </List>
              </Collapse>
            </div>
            :
            <Link key={index} className='navLink' activeClassName='active' to={`/${item.path}`}>
              <ListItem button>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
        )}
        <Link className='navLink' activeClassName='active' to="/contacto/">
          <ListItem button>
            <ListItemText primary='Contacto' />
          </ListItem>
        </Link>
      </nav>
    )
    return (
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <MobileList className='mobileList' style={{ padding: 0 }}>
          {navMenu()}
        </MobileList>
      </div>
    )
  }

  return (
    <div>
      <Drawer
        anchor='left'
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </Drawer>
    </div>
  )
}

const MobileList = styled(List)`
  .navLink{
    color: #000;
    &.active > div{
      background-color: rgba(0, 126, 134, 0.15);
      border-right: 8px solid #007E86;
      box-shadow: none;
    }
    div[className^="MuiListItem-gutters"]{
      padding-left:30px;
    }
    .icon{
      font-size: 1.2rem;
    }
  }
`

export default MenuMobile