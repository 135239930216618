import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import {
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core"
import lightTheme from "../themes/light"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

import { createTheme } from '@material-ui/core/styles'

const Layout = ({ children, location }) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 400,
        sm: 768,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      primary: {
        light: "#757ce8",
        main: "#00ABC2",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#999",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    typography: {
      h1: {
        fontFamily: "Questrial",
        fontSize: "2.5rem",
      },
      h2: {
        fontFamily: "Questrial",
        fontSize: "2rem",
      },
      h3: {
        fontFamily: "Questrial",
        fontSize: "1.5rem",
      },
      h4: {
        fontFamily: "Questrial",
        fontSize: "1.3rem",
      },
      fontFamily: ["Raleway", "sans-serif"].join(","),
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 18,
          fontSize: ".8rem",
          textTransform: "none",
          letterSpacing: "0.08em",
        },
        containedSecondary: {
          color: 'white',
          backgroundColor: '#269094FF',
          '&:hover': {
            backgroundColor: '#269094CC'
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: '#2690941A'
          },
        },
        outlined: {
          padding: "5px 30px",
        },
      },
      MuiDrawer: {
        paper: {
          top: "74px",
          height: "calc(100% - 74px);",
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "none",
        },
      },
      MuiListItem: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
        },
        gutters: {
          paddingLeft: 30,
          paddingRight: 16,
        },
      },
      MuiFormControl: {
        root: {},
      },
      MuiFilledInput: {
        root: {
          backgroundColor: "rgba(255,255,255,0.2)",
          borderRadius: 4,
          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.3)",
            boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
          },
          "&.Mui-focused": {
            backgroundColor: "rgba(255,255,255,0.25)",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
          },
        },
        underline: {
          "&:hover:before": {
            borderBottom: "0",
          },
          "&:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            left: 2,
            right: 2,
            transition: "none",
          },
          "&:after": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
            left: 2,
            right: 2,
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: "rgba(255,255,255,.5)",
          fontSize: 14,
          "&.Mui-focused": {
            color: "rgba(255,255,255,.5)",
          },
        },
      },
      MuiInputBase: {
        root: {
          color: "#fff",
          fontSize: 14,
        },
      },
      root: {
        "& .MuiInputLabel-root": {
          color: "blue",
          fontSize: 14,
        },
      },
    },
  })
  const GlobalStyle = createGlobalStyle`
    html {
      font-size: 100%;
      height: 100%;
    }
    body {
      background-color: ${props => props.theme.body.background};
      height: 100%;
    }
    #___gatsby,
    #gatsby-focus-wrapper{
      height: 100%;
    }
    h2.title{
      font-size: 1.25rem;
      margin-bottom: 0;
      @media screen and (min-width: 600px){
        font-size: 2rem;
      }
    }
  `
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyle />
        <CustomGird>
          <Header location={location.pathname} />
          <main>{children}</main>
          <Footer />
        </CustomGird>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const CustomGird = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  main {
    padding-top: ${props => props.theme.header.height.mobile};
    @media screen and (min-width: 960px) {
      padding-top: ${props => props.theme.header.height.desktop};
    }
  }
`
